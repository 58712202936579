import logo from './logo.svg';
import React from 'react';
import ReactPlayer from "react-player";
import './App.css';

function App() {
  const [playing, setPlaying] = React.useState(true);
  return (
    <div className="App">

      <ReactPlayer
        url={"https://www.youtube.com/watch?v=2E1GQOWa2v0"}
        autoPlay={true}
        playing={true}
        onReady={() => setPlaying(true)}
        controls={true}
        width={"100%"}
        height={"100%"}
      >

      </ReactPlayer>

      {/* <iframe className='youtubeIframe' width="100%" height="670" src="https://www.youtube.com/embed/2E1GQOWa2v0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}


      {/* <h3>
        Something Special is Coming Soon...
      </h3> */}
    </div>
  );
}

export default App;
